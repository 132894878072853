<template>
  <div>
    <!-- 分页 -->
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pager.currentPage"
      :page-sizes="privatePageSizeList"
      :page-size="privatePageSize"
      :layout="baseConfig.layout"
      :total="pager.total">
      <slot></slot>
    </el-pagination>
  </div>
</template>

<script>
  import { paginationConfigBase } from 'utils/common.js'
  export default {
    name: 'Pagination',
    props: {
      pager: {
        type: Object
      },
      pageSizes: {
        type: Array,
        default: null
      }
    },
    emits: ['getPager'],
    data() {
      return {
        hasChangeSize: false,
        canChangePage: true,
        baseConfig: paginationConfigBase(),
        privatePageSizeList: null,
        privatePageSize: null,
      }
    },
    created(){
        this.notifyPrivatePageSizesConf()
    },
    methods: {

      notifyPrivatePageSizesConf(){
          this.privatePageSizeList = this.pageSizes ? this.pageSizes: this.baseConfig.pageSizes;
          this.privatePageSize = this.pager.pageSize ? this.pager.pageSize: this.baseConfig.pageSize;
      },

      // 每页条数
      handleSizeChange(val) {
        this.pager.pageSize = val
        this.pager.currentPage = 1
        this.$emit('getPager', this.pager);  //向父元素传递变动数据
        this.hasChangeSize = true
      },
      // 翻页
      handleCurrentChange(val) {
        if(this.canChangePage && !this.hasChangeSize) {
          this.$emit('getPager', Object.assign(this.pager, {currentPage: val})); //向父元素传递变动数据
        }
      },
    },
    watch: {
      hasChangeSize(val) {
        if(val) {
          this.canChangePage = false
        } else {
          this.canChangePage = true
        }
      },
      canChangePage(val) {
        if(!val) {
          this.hasChangeSize = false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .el-pagination {
    padding-top:20px;
    text-align: right;
    :deep(.el-pagination__total), :deep(.el-pagination__sizes) {
      float:left;
    }
    :deep(.pagination_dropdown) {
      float:left;
      cursor: pointer;
      > span {
        border: 1px solid #DCDFE6;
        padding: 0 10px;
        border-radius: 3px;
        font-weight: normal;
        > i {
          color: #C0C4CC;
        }
      }
    }
  }

</style>
