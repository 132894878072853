/**
 * 面授创建API
 */
import { fetch, post, del } from 'api/base/axios'

const URL = {
  filterUrl: '/curriculum/cur/list/',
  courseExportUrl: '/curriculum/cur/course_export/',
  userListUrl: '/curriculum/cur/user_list/',
  userOwnerListUrl: '/curriculum/cur/user_owner_list/',
  userOwnerFreeListUrl: '/curriculum/cur/user_owner_free_list/',
  delUrl: '/curriculum/cur/delete/',
  publishUrl: '/curriculum/cur/publish/',
  userPurchasedCurUrl: '/curriculum/cur/purchased_cur_list/',
  usercenterCourseListUrl: '/curriculum/cur/center_course_list/',
  userFreeCurUrl: '/curriculum/cur/free_cur_list/',
  exportCurUrl: '/curriculum/cur/export/',
  exportTaskQueryUrl: '/curriculum/cur/export/query/',
  checkUserCanStudyUrl: '/curriculum/cur/check_user_can_study/',
}

/**
 * 查询用户
 * @param data
 * @returns {Promise}
 */
export function listCur (data) {
  return post(URL.filterUrl, data)
}
export function courseExport (data) {
  return fetch(URL.courseExportUrl, data)
}

export function delCur (uuid) {
  return del(URL.delUrl + uuid + '/')
}

export function listUserCur(data) {
  return fetch(URL.userListUrl, data)
}

export function userOwnerListCur(data) {
  return fetch(URL.userOwnerListUrl, data)
}
export function userOwnerListFreeCur(data) {
  return fetch(URL.userOwnerFreeListUrl, data)
}
export function publishCur(uuid, data){
  return post(URL.publishUrl + uuid + '/', data)
}

export function listUserPurchasedCur(data) {
  return fetch(URL.userPurchasedCurUrl, data)
}

export function centerCoursesList(data) {
  return fetch(URL.usercenterCourseListUrl, data)
}

export function listUserFreeCur(data) {
  return fetch(URL.userFreeCurUrl, data)
}

export function exportCur(data) {
  return post(URL.exportCurUrl, data)
}

export function queryExportCurTask() {
  return fetch(URL.exportTaskQueryUrl)
}

export function checkUserCanStudy (data) {
  return post(URL.checkUserCanStudyUrl, data)
}
