<template>
  <div>
    <div class="well_frame">
      <h4 class="frame_title">我的课程</h4>
      <template v-if="centercourseList.tableData.length !== 0">
        <ul class="course_list_common pointer"  >
          <li v-for="(item, index) in centercourseList.tableData"  :key="index">
            <div class="cover" @click="goPath('/course/detail/'  + item.uuid)">
              <img :src="item.cover_image" class="image" />
              <span class="hot" v-if="item.cover_image_label && item.cover_image_label != 'null'">{{ item.cover_image_label }}</span>
              <span class="tag" v-if="item.type">{{ item.type }}</span>
            </div>
            <div class="content">
              <h4 @click="goPath('/course/detail/'  + item.uuid)">{{ item.name }}</h4>
              <div class="info">
                课时:&#12288;{{item.chapter_num}}章{{item.video_num + item.live_num + item.face_num + item.auth_num + item.experiment_num}}节
                <span style="color:#f51c21;" v-if="item.state == '已失效'">{{ item.state }}</span>
                <span v-else-if="item.state !== '' && item.state != '永不过期'">{{ item.state }}</span>
              </div>
            </div>
          </li>
        </ul>
        <!-- 分页 -->
        <Pagination :pager="centercourseList.pager" @getPager="getCenterPager"></Pagination>
      </template>
      <el-empty description="暂无课程" v-else></el-empty>
    </div>
  </div>
</template>

<script>
  import Pagination from 'components/common/Pagination'
  import { listUserPurchasedCur, listUserFreeCur, centerCoursesList } from "api/course/course/list"
  import { GetUserState } from 'api/user/auth/login'
  export default {
    components: {
      Pagination
    },
    data() {
      return {
        courseItem : [],
        freeItem : [],
        pager:{},
        tabActiveName: 'join',
        centercourseList: {
          loading: true,
          tableData:[],
          pager: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
          }
        },
        form:{
          owner_id : ''
        },
        user:{
          user_id: null
        },
      }
    },
    mounted() {
      this.getCenterList();
  },
    methods: {
      getCenterList(){
        const that = this
        let filters = {}
        filters['offset'] = (that.centercourseList.pager.currentPage - 1) * that.centercourseList.pager.pageSize
        filters['limit'] = that.centercourseList.pager.pageSize
        centerCoursesList(filters).then(res => {
          that.centercourseList.tableData = that.formatData(res.data)
          that.centercourseList.pager.total = res.total
          that.centercourseList.loading = false
        })
      },
      formatData(data) {
        for(let i=0; i<data.length; i++){
          let item = data[i]
          item['chapter_info'] = item.cur_chapter.length + '章' + (item.live_num + item.video_num + item.auth_num + item.face_num + item.experiment_num) +'节'
        }
        return data
      },
      getOwner(){
        const that = this;
        GetUserState().then((res) => {
          if (res.id) {
            this.user.user_id = res.id
            var owner_id = this.user.user_id
            this.form.owner_id = owner_id
            that.getCenterList();
          }else {
            this.form.owner_id = 'NO-USER'
            that.getCenterList();
          }
        })
      },
      goPath(path) {
        window.open(path,'_blank')
      },
      getCenterPager(pager) { //获取分页变化后数据
        this.getCenterList()
        this.centercourseList.pager = pager
      },
    }
  }
</script>
