export function tableConfigBase(options) { //表格公共配置
  const defaultConfig = {
    height: 560,
  }
  Object.assign(defaultConfig, options);
  return defaultConfig;
}

export function paginationConfigBase(options) { //分页公共配置
  const defaultConfig = {
    pageSizes: [10, 50, 100],
    pageSize: 10,
    layout: 'total, sizes, slot, prev, pager, next, jumper'
  }
  Object.assign(defaultConfig, options);
  return defaultConfig;
}

export function isNumber (val) { //带小数数字校验
  var regPos = /^\d+(\.\d+)?$/
  var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/
  if (regPos.test(val) || regNeg.test(val)) {
    return true
  } else {
    return false
  }
}

export function checkPrice (rule, value, callback) {  //金额校验
  const required = rule.required || rule._required;
  if(required) {
    if (!value && value != 0) {
      return callback(new Error('请填写数值'));
    }
    if (value < 0) {
      return callback(new Error('请输入大于等于0的数值'));
    }
    if (!isNumber(value)) {
      callback(new Error('请输入数字值'));
    } else {

      if (value && Math.round(value * 100) / 100 != value) {
        return callback(new Error('金额仅支持小数点2位'));
      } else {
        callback();
      }

    }
  } else {
    if(value) {
      if (value < 0) {
          return callback(new Error('请输入大于等于0的数值'));
        }
        if (!isNumber(value)) {
          callback(new Error('请输入数字值'));
        } else {
          if (value && Math.round(value * 100) / 100 != value) {
            return callback(new Error('金额仅支持小数点2位'));
          } else {
            callback();
          }
        }
    } else {

      if (value && Math.round(value * 100) / 100 != value) {
        return callback(new Error('金额仅支持小数点2位'));
      } else {
        callback();
      }
    }
  }
}
export function checkRate (rule, value, callback) { //折扣率校验
  const required = rule.required || rule._required;;
  if(required) {
    if (!value) {
      return callback(new Error('请填写数值'));
    }
    if (value <= 0 || value >= 10) {
      return callback(new Error('请输入介于0~10之间的数值'));
    }
    if (!isNumber(value)) {
      callback(new Error('请输入数字值'));
    } else {

      if (Math.round(value *10)/10 != value) {
        return callback(new Error('折扣仅支持小数点1位'));
      } else {
        callback();
      }

    }
  } else {
    if(value) {
      if (value <= 0 || value >= 10) {
        return callback(new Error('请输入介于0~10之间的数值'));
      }
      if (!isNumber(value)) {
        callback(new Error('请输入数字值'));
      } else {
        if (Math.round(value *10)/10 != value) {
          return callback(new Error('折扣仅支持小数点1位'));
        } else {
          callback();
        }
      }
    } else {

      if (Math.round(value *10)/10 != value) {
        return callback(new Error('折扣仅支持小数点1位'));
      } else {
        callback();
      }

      callback();
    }
  }

}
export function checkPhone (rule, value, callback) {  //电话校验
  const  required = rule.required || rule._required;
  const phoneReg = /^1[0-9]{10}$/;
  const telReg = /(^(\d{2,4}[-_－—]?)?\d{3,8}([-_－—]?\d{3,8})?([-_－—]?\d{1,7})?$)|(^0?1[35]\d{9}$)/;
  if(required) {  //必填
    if (!value) {
      return callback(new Error('手机号码不能为空'));
    }
    phoneReg.test(value) ? callback() : callback(new Error('手机号码格式不正确'));
  } else {  //非必填
    if(value) {
      phoneReg.test(value) ? callback() : callback(new Error('手机号码格式不正确'));
    } else {
      callback();
    }
  }
}

export function checkConnectionPhone (rule, value, callback) {  //客服电话校验
  const  required = rule.required || rule._required;
  const phoneReg = /^1[0-9]{10}$/;
  const telReg = /(^(\d{2,4}[-_－—]?)?\d{3,8}([-_－—]?\d{3,8})?([-_－—]?\d{1,7})?$)|(^0?1[35]\d{9}$)/;
  if(required) {  //必填
    if (!value) {
      return callback(new Error('手机号码不能为空'));
    }
    phoneReg.test(value) ? callback() : callback(new Error('手机号码格式不正确'));
  } else {  //非必填
    if(value) {
      telReg.test(value) ? callback() : callback(new Error('电话号码格式不正确'));
    } else {
      callback();
    }
  }
}

export function checkEmail (rule, value, callback) { //邮箱校验
  const  required = rule.required || rule._required;
  const mailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  if(required) {  //必填
    if (!value) {
      return callback(new Error('邮箱不能为空'));
    }
    mailReg.test(value) ? callback() : callback(new Error('请输入正确的邮箱格式'));
  } else {  //非必填
    if(value) {
      mailReg.test(value) ? callback() : callback(new Error('请输入正确的邮箱格式'));
    } else {
      callback();
    }
  }
}

export function checkTaxpayerNum(rule, value, callback) { //纳税人识别号校验
  const required = rule.required || rule._required;
  const tarNum = /^(([0-9A-Z]{15})|([0-9A-Z]{18})|([0-9A-Z]{20}))$/;

  if (required) { //必填
    if (!value) {
      return callback(new Error('纳税人识别号不能为空'));
    }
    tarNum.test(value) ? callback() : callback(new Error('请输入正确的纳税人识别号格式(无需加 - )'));
  } else {
    if (values) {
      tarNum.test(value) ? callback() : callback(new Error('请输入正确的纳税人识别号格式'));
    } else {
      callback();
    }
  }
}

export function checkcertificateNum(rule, value, callback) { //证书编号格式校验
  const required = rule.required || rule._required;
  const tarNum = /^(([0-9]{14}))$/;

  if (required) { //必填
    if (!value) {
      return callback(new Error('证书编号不能为空'));
    }
    tarNum.test(value) ? callback() : callback(new Error('请输入正确的证书编号格式'));
  } else {
    if (values) {
      tarNum.test(value) ? callback() : callback(new Error('请输入正确的证书编号'));
    } else {
      callback();
    }
  }
}

// export  function checkCode(rule, value, callback) {  //验证码校验
//   const required = rule.required || rule._required;
//   console.log(required)
//   let identifyStr = this.identifyCode.toLowerCase()
//   if (required === '') {
//       callback(new Error('请输入验证码'))
//   } else if (required !== identifyStr) {
//       console.log('validateVerifycode:', value)
//       callback(new Error('验证码不正确'))
//   } else {
//       callback()
//   }
// }

export function checkTrainPhone (rule, value, callback) {  //手机号+固定电话校验
  const  required = rule.required || rule._required;
  const phoneReg = /^((1[0-9]{10})|(([0-9]{3,4}-)?[0-9]{7,8}))$/;
  if(required) {  //必填
    if (!value) {
      return callback(new Error('手机号码不能为空'));
    }
    phoneReg.test(value) ? callback() : callback(new Error('手机号码格式不正确(固话请加-)'));
  } else {  //非必填
    if(value) {
      phoneReg.test(value) ? callback() : callback(new Error('手机号码格式不正确(固话请加-)'));
    } else {
      callback();
    }
  }
}


export function getPagerOffsetLimit (pager) {

  let current_page = pager.currentPage;
  let page_size = pager.pageSize;
  let total_count = pager.total;
  let max_page = 0;
  let offset_index = 0;

  if (total_count < page_size) {
    max_page =  1;
  } else if (total_count % page_size == 0) {
    max_page =  total_count / page_size;
  } else {
    max_page  = Math.ceil(total_count / page_size);
  }

  if (current_page <= max_page){

    offset_index = page_size * (current_page - 1);

  } else {
    offset_index = 0
  }

  console.log(current_page, page_size, total_count);
  console.log('max_page', max_page);

  return {
    'offset': offset_index > 0 ? offset_index : 0,
    'limit': page_size
  }

}


export function generateUUID() {
  var d = new Date().getTime();
  if (window.performance && typeof window.performance.now === "function") {
    d += performance.now(); //use high-precision timer if available
  }
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}

export function checkPassword (rule, value, callback) { //密码校验
  const  required = rule.required || rule._required;
  const mailReg = /^[^\s]{6,16}$/;
  if(required) {  //必填
    if (!value) {
      return callback(new Error('密码不能为空'));
    }
    mailReg.test(value) ? callback() : callback(new Error('密码长度6-16位，不允许有空格'));
  } else {  //非必填
    if(value) {
      mailReg.test(value) ? callback() : callback(new Error('密码长度6-16位，不允许有空格'));
    } else {
      callback();
    }
  }
}

export function checkUserName (rule, value, callback) {
  const required = rule.required || rule._required;
  const mailReg = /^[^\s@]{3,18}$/;
  const numReg = /^\d+$/;
  if (required) {  //必填
    if (!value) {
      return callback(new Error('账号不能为空'));
    }
  } else {  //非必填
    if(!value) {
      return callback();
    }
  }
  if (value) {
    if (!mailReg.test(value)) {
      return callback(new Error('账号长度3-18位，不允许有@字符或空格或者纯数字'));
    }
    if (numReg.test(value)) {
      return callback(new Error('账号组成不可为纯数字'));
    }
  }
  return callback();
}

export function checkRealName (rule, value, callback) {
  const required = rule.required || rule._required;
  const mailReg = /^[^\s@]{1,18}$/;
  if(required) {  //必填
    if (!value) {
      return callback(new Error('姓名不能为空'));
    }
    mailReg.test(value) ? callback() : callback(new Error('姓名长度1-18位，不允许有@字符或空格'));
  } else {  //非必填
    if(value) {
      mailReg.test(value) ? callback() : callback(new Error('姓名长度1-18位，不允许有@字符或空格'));
    } else {
      callback();
    }
  }
}

  export function formateSeconds(endTime){
  let secondTime = parseInt(endTime)
  let min = 0
  let h =0
  let result=''
  if(secondTime>=60){
    min=parseInt(secondTime/60)
    secondTime=parseInt(secondTime%60)
    if(min>=60){
      h=parseInt(min/60)
      min=parseInt(min%60)
    }
  }
  result=`${h.toString().padStart(2,'0')}:${min.toString().padStart(2,'0')}:${secondTime.toString().padStart(2,'0')}`
  return result
}


export function unitProcess(data){
  data = parseFloat(data)
  let unit = 'b'
  if (data > 1024){
    data = data/1024 //KB
    unit = 'Kb'
    if (data > 1024){
      data = data/1024 //MB
      unit = 'Mb'
      if (data >1024){
        data = data/1024 //GB
        unit = 'Mb'
      }
    }
  }
  data = data.toFixed(2)
  return data + unit
}

export function is_mobile() {
  var isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i) ? true : false;
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i) ? true : false;
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false;
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i) ? true : false;
    },
    any: function () {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Windows());
    }
  };
  return isMobile.any();
}

export function is_weixin() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
}

export function copyToClipBoard(data) {
  let url = data;
  let oInput = document.createElement('textarea');
  oInput.value = url;
  document.body.appendChild(oInput);
  oInput.select(); // 选择对象;
  document.execCommand('Copy'); // 执行浏览器复制命令
  console.log('复制成功');
  oInput.remove();
}

export function getUrlKey(name,url){
　　return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [, ""])[1].replace(/\+/g, '%20')) || null
}

export function routePathHandler(routePath) {  //route路径处理
  if(routePath.charAt(routePath.length - 1) === '/') {
    routePath = routePath.slice(0, routePath.length - 1);
  }
  return routePath;
}

export function goPathToNewPage(path) {    //打开新标签页页面
  window.open(path, '_blank')
}

export function isMobile() {
  let result = false;
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
     result = true;
  }
  return result;
}

export function htmlEncode(str) {
  str = str.replace(/&amp;/gi, '&');
  str = str.replace(/</g, '&lt;');
  str = str.replace(/>/g, '&gt;');
  return str;
}

export function getUrlParam(name) {

  let param_dict = {};

  let param_list = location.search.replace('?', '').split('&')
  param_list.forEach((item, index) => {
    let item_splits = item.split('=')
    param_dict[item_splits[0]] = item_splits[1]
  });

  return eval("param_dict." + name);

}



export function checkIDCardNo(rule, value, callback) { //身份证号校验
  const required = rule.required || rule._required;

  if (!value){
     return callback(new Error('身份证号不能为空'));
  }
  if (value.length != 18){
    callback(new Error('请输入正确的身份证号'))
  }

  if (isNaN(value.slice(0,17))){
    callback(new Error('请输入正确的身份证号'))
  }

  if (Number(value.slice(6,10)) % 4 == 0 || (Number(value.slice(6,10)) % 100 == 0 && Number(value.slice(6,10)) % 4 == 0)){
      // # 出生日期闰年时合法性正则表达式
      var birthday = /^[1-9][0-9]{5}(19[0-9]{2}|20[0-9]{2})((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/
  }
  else{
      // # 出生日期平年时合法性正则表达式
      var birthday = /^[1-9][0-9]{5}(19[0-9]{2}|20[0-9]{2})((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/
  }
  if (!value.match(birthday)){
      callback(new Error('请输入正确的身份证号'))
  }


  var factor = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ];
  var parity = [ 1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2 ];
  var code = value.substring(17);

  if (required) { //必填

    if(birthday.test(value)) {
        var sum = 0;
        for(var i=0;i<17;i++) {
            sum += value[i]*factor[i];
        }
        if(parity[sum % 11] == code.toUpperCase()) {
            callback();
        }
    }
    callback(new Error('请输入正确的身份证号'))
  }

}

